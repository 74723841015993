<template>
  <div class="h-px print:!hidden" ref="pinner" />
  <header
    class="sticky top-0 bg-white z-[9999] print:!hidden transition-transform duration-500"
    :class="{
      'translate-y-0': headerVisible,
      '-translate-y-full': !headerVisible,
    }"
    ref="header"
  >
    <div>
      <div class="py-3 md:py-6 border-b border-gray-300">
        <div
          class="container relative flex flex-row items-center justify-between"
        >
          <div class="z-50 flex">
            <NuxtLink
              :to="{ name: 'frontpage' }"
              class="flex items-center shrink-0"
            >
              <div
                v-if="!sagaPaperStore.isFrontPagePaper"
                class="hidden md:block md:w-10"
              >
                <CommonFontAwesomeIcon
                  class="text-lg !align-middle ml-2 mr-4"
                  icon="fa-regular fa-arrow-left"
                />
              </div>

              <div
                class="h-[24px] md:h-[40px] origin-left inline-flex relative transform-gpu transition-all duration-300"
              >
                <img src="/A_Logo.svg" alt="Altinget logo" />
              </div>
            </NuxtLink>
            <!-- Primary menu. Hide on mobile and paper pages -->
            <ul
              v-if="sagaPaperStore.isFrontPagePaper"
              class="hidden xl:flex items-center gap-5 ml-6"
            >
              <template v-for="item in primaryMenuItems" :key="item.label">
                <li>
                  <NuxtLink
                    :to="`${getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)}`"
                    class="font-sans text-lg hover:text-blue"
                    active-class="text-blue"
                  >
                    {{ $t(item.label) }}
                  </NuxtLink>
                </li>
              </template>
            </ul>
          </div>

          <CommonBuySubscriptionButton
            class="hidden sm:flex ml-auto mr-6"
            v-if="displayBuyButton"
          />

          <div class="flex items-center justify-center gap-x-4 md:gap-x-5">
            <LayoutDkLogin :headerVisible="headerVisible" />
            <LayoutDkNichemedia :headerVisible="headerVisible" />

            <LayoutDkMenuToggle :headerVisible="headerVisible" />
          </div>
        </div>
      </div>
      <CommonBuySubscriptionButton class="sm:hidden" v-if="displayBuyButton" />
      <div
        v-if="!sagaPaperStore.isFrontPagePaper"
        class="bg-[#F3F5F7] z-[15] print:!hidden"
      >
        <div class="container">
          <div
            class="flex flex-col md:flex-row md:items-center h-[96px] md:h-[74px]"
          >
            <NuxtLink
              v-if="!sagaPaperStore.isFrontPagePaper"
              :to="{
                name: 'paper',
                params: { paper: sagaPaperStore.activePaper.slug },
              }"
              class="flex items-center font-bold pt-4 pb-1 md:py-6 text-blue text-2xl font-sans gap-4"
            >
              <img
                src="/A_Logomark.svg"
                alt="Altinget logo"
                class="aspect-square w-6"
              />
              <span>{{ sagaPaperStore.activePaper.name }}</span>
            </NuxtLink>
            <ul class="flex flex-column md:flex-row md:ml-6 h-full gap-5">
              <template v-for="item in paperMenuItems" :key="item.label">
                <li class="h-full relative">
                  <NuxtLink
                    :to="`${getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)}`"
                    class="font-sans text-sm md:text-lg h-full flex items-center after:content[''] after:absolute after:bottom-0 after:w-full after:h-0.5 after:bg-transparent hover:after:bg-blue"
                    active-class="after:bg-blue"
                  >
                    {{ $t(item.label) }}
                  </NuxtLink>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        headerVisible &&
        isSponsoredRoute &&
        indexStore.headerState.sponsoredBannerActive
      "
      class="bg-[#020136] font-sans text-white text-2xl py-3 font-bold z-50"
    >
      <div class="container">
        <span class="md:px-11">{{ $t('PayedArticle') }}</span>
      </div>
    </div>
  </header>
  <div
    v-if="
      !headerVisible &&
      isSponsoredRoute &&
      indexStore.headerState.sponsoredBannerActive
    "
    class="sticky top-0 bg-[#020136] font-sans text-white text-2xl py-3 font-bold z-50"
  >
    <div class="container">
      <span class="md:px-11">{{ $t('PayedArticle') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
// Composables
const nuxtApp = useNuxtApp()
const sagaPaperStore = useSagaPaperStore()
const indexStore = useIndexStore()
const route = useRoute()
const userStore = useUserStore()

// Refs
const pinner = ref<HTMLElement>()
const headerVisible = ref(true)

// Variables
const primaryMenuItems = getPrimaryMenuItems()
const paperMenuItems = getPaperMenuItems()
let lastScrollPosition = 0

// Computed
const routePrefix = computed(() => {
  return route.params.paper ? `/${route.params.paper}/` : '/'
})

const isSponsoredRoute = computed(() => {
  return route.name === 'sponsored_id' || route.name === 'sponsored_paper_id'
})
const displayBuyButton = computed(() => {
  const hasSubscription = sagaPaperStore.activePaper.id
    ? userStore.hasSubscription(sagaPaperStore.activePaper.id)
    : false

  return (
    !['papers', 'subscription', 'magazine'].includes(route.name as string) &&
    !hasSubscription
  )
})

// Methods
const handleScroll = () => {
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop
  headerVisible.value =
    currentScrollPosition < lastScrollPosition ||
    currentScrollPosition <= (!sagaPaperStore.isFrontPagePaper ? 140 : 0) // Hide header not immediately on scroll down, but after 140px - header height on paper pages
  lastScrollPosition = currentScrollPosition
}

// Lifecycle
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
