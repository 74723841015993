<template>
  <div
    class="rounded-b flex flex-col custom-scrollbar w-full md:w-80 h-[calc(100dvh-66px)] md:h-auto md:max-h-[86vh] overflow-scroll z-40 absolute top-[13px] md:top-7 right-0 md:right-12 bg-white md:shadow pt-5 transition-all duration-200 transform overscroll-none"
    :class="{
      'opacity-100 translate-y-10': open,
      'opacity-0 translate-y-12 pointer-events-none': !open,
    }"
  >
    <div class="mx-5">
      <div
        class="flex items-center w-full mt-1 mb-8 px-4 py-2 rounded-full border border-gray-300"
      >
        <SearchIcon
          class="w-5 h-5 transition-all duration-200 fill-current hover:text-blue"
        />
        <input
          ref="search"
          v-model="searchWords"
          type="text"
          class="article-label outline-none bg-white transition-all duration-300 py-2 ml-2"
          :placeholder="$t('SearchOnAltinget')"
          @keyup.enter="onSearch"
        />
      </div>
    </div>
    <!-- Primary menu. Hide on desktop and paper pages -->
    <ul v-if="sagaPaperStore.isFrontPagePaper" class="mb-5 xl:hidden">
      <li class="list-label mb-4 opacity-60 mx-5">Sektioner</li>

      <template v-for="item in menuItems" :key="item.label">
        <li>
          <NuxtLink
            :to="getMenuItemUrl(nuxtApp.$te(item.url) ? nuxtApp.$t(item.url) : item.url, item.noPrefix as boolean, routePrefix)"
            class="flex font-sans text-xl py-3 px-5 hover:bg-gray-100"
            active-class="text-gray-500 pointer-events-none"
          >
            {{ $t(item.label) }}
          </NuxtLink>
        </li>
      </template>
    </ul>
    <ul>
      <li
        class="md:break-after-column"
        v-for="(item, index) in menu.header"
        :key="index"
      >
        <div class="list-label mb-4 opacity-60 mx-5">{{ item.headline }}</div>
        <ul :class="index === menu.header.length - 1 ? 'mb-3' : 'mb-8'">
          <li
            v-for="(subitem, subItemIndex) in item.submenu"
            :key="'submenu' + subItemIndex"
          >
            <NuxtLink
              v-if="subitem.link.indexOf('https') == -1"
              :to="subitem.link"
              class="flex font-serif text-[15px] px-5 py-1.5 hover:bg-gray-100"
              active-class="text-gray-500 pointer-events-none"
            >
              {{ subitem.headline }}
            </NuxtLink>
            <a
              v-else
              :href="subitem.link"
              class="font-serif text-[15px] flex px-5 py-1.5 hover:bg-gray-100"
            >
              {{ subitem.headline }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import SearchIcon from '~/assets/icons/search-slim.svg?component'
// Properties
withDefaults(
  defineProps<{
    open: boolean
  }>(),
  {
    open: false,
  }
)

// Composables
const indexStore = useIndexStore()
const sagaPaperStore = useSagaPaperStore()
const nuxtApp = useNuxtApp()
const route = useRoute()

// Refs
const searchWords = ref('')
const search = ref<HTMLInputElement>()
const menuItems = getPrimaryMenuItems()

// Computed
const routePrefix = computed(() => {
  return route.params.paper ? `/${route.params.paper}/` : '/'
})
const menu = computed(() => indexStore.menu)

// Methods
const onSearch = async () => {
  await navigateTo({
    name: sagaPaperStore.isFrontPagePaper ? 'searchwords' : 'paper_searchwords',
    params: {
      words: searchWords.value,
      paper: sagaPaperStore.isFrontPagePaper
        ? undefined
        : sagaPaperStore.activePaper.slug,
    },
  })
}
</script>
