<template>
  <NuxtLink
    :to="{
      name: sagaPaperStore.isFrontPagePaper ? 'subscription' : 'papers',
    }"
    class="button-l border-b border-gray-300 flex justify-center items-center h-[36px] sm:h-[38px] px-5 bg-blue text-white whitespace-nowrap cursor-pointer sm:rounded-full sm:border sm:bg-blue hover:bg-denimblue hover:text-white"
  >
    {{
      sagaPaperStore.isFrontPagePaper
        ? $t('BuyAbonnement')
        : $t('CreateProfile')
    }}
  </NuxtLink>
</template>

<script setup lang="ts">
const sagaPaperStore = useSagaPaperStore()
</script>
