<template>
  <div
    data-testid="navbar-user-component"
    class="flex flex-col items-center justify-center"
    ref="loginModal"
  >
    <div class="cursor-pointer z-20" @click="toggle">
      <span
        class="flex flex-col list-label items-center justify-center !font-medium transition-color duration-200"
        :class="{
          'text-blue': isLoginPopupOpen,
          'hover:text-gray-600': !isLoginPopupOpen,
        }"
      >
        <template v-if="userStore.user">
          <ProfileIcon />
          <span
            data-testid="user-logged-in"
            class="hidden md:inline-block truncate max-w-[100px] mt-2"
            :title="$t('MyProfile')"
            >{{
              userStore.hasLoginType('IP', 'Wayf')
                ? userStore.user.name
                : $t('MyProfile')
            }}
          </span>
          <span class="md:hidden mt-2">{{
            userStore.hasLoginType('IP', 'Wayf')
              ? userStore.user.name
              : $t('MyProfile')
          }}</span>
        </template>

        <CommonButton
          v-else
          size="large"
          bg="bg-gray-300"
          text="text-black"
          border="border-none"
          data-testid="nav-login-button"
        >
          {{ $t('Login') }}
        </CommonButton>
      </span>
    </div>
    <!-- Login Popup -->
    <div
      v-if="isLoginPopupOpen"
      data-testid="navbar-login-popup"
      class="rounded-b top-[52px] md:top-[68px] right-0 md:right-40 z-10 min-w-48 absolute bg-white shadow px-2 py-2 transition-all duration-200 transform"
    >
      <CommonLogin
        v-if="!userStore.user"
        ref="login"
        :try-login="true"
        v-model:email="input.email"
        v-model:password="input.password"
        @loggedIn="hide"
      />
      <div v-else data-testid="navbar-mit-altinget">
        <NuxtLink
          v-if="userStore.hasLoginType('UsernamePassword', 'Sso')"
          :to="{ name: 'account' }"
          @click="toggle"
          class="label-s text-gray-500 uppercase px-2 py-3"
        >
          {{ false ? $t('MyMandagMorgen') : $t('MyAltinget') }}
        </NuxtLink>
        <ul class="list-title-xxxs !font-medium">
          <template v-if="userStore.hasLoginType('UsernamePassword', 'Sso')">
            <li
              v-for="item in menuItems"
              class="px-2 py-3 rounded hover:bg-gray-100 transition-bg duration-100"
            >
              <NuxtLink :to="item.route" @click="toggle" class="select-none">
                <CommonFontAwesomeIcon
                  class="text-sm text-gray-700 w-3.5 mr-1.5"
                  :icon="item.icon"
                />
                {{ $t(item.label) }}
              </NuxtLink>
            </li>
            <CommonDivider customMargin="my-2" />
          </template>
          <li
            class="flex items-center cursor-pointer px-2 py-3 rounded hover:bg-gray-100 transition-bg duration-100"
            @click="logout"
          >
            <img
              src="~/assets/icons/icon-action-logout.svg?url"
              class="w-4 h-4 mr-2.5 inline-block"
            />
            {{ $t('Logout') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProfileIcon from '@/assets/icons/icon-login.svg?component'
import { onClickOutside } from '@vueuse/core'
import { InputFieldInjectionKey } from '~/typesManual/InjectKeys'

const userStore = useUserStore()
const route = useRoute()
const { menuItems } = await useAccountMenu()

const props = withDefaults(
  defineProps<{
    headerVisible: boolean
  }>(),
  { headerVisible: false }
)

const loginInputField = ref<HTMLInputElement>()
const loginModal = ref<HTMLDivElement>()
const isLoginPopupOpen = ref(false)
const input = ref({
  email: '',
  password: '',
})

watch(
  () => props.headerVisible,
  (newVal) => {
    if (!newVal) {
      hide()
    }
  }
)

// Methods
const toggle = () => {
  isLoginPopupOpen.value = !isLoginPopupOpen.value

  if (isLoginPopupOpen.value && loginInputField?.value) {
    loginInputField.value.focus()
  }
}
const hide = () => {
  isLoginPopupOpen.value = false
}

async function logout() {
  if (!userStore.user) {
    return
  }

  if (userStore.user.loginType !== 'Sso') {
    const protectedPathListRegex =
      useRuntimeConfig().public.protectedPathList.map((item) => {
        if (item[item.length - 1] === '*') {
          return RegExp(`^${item.replaceAll('*', '.*')}$`)
        }
        return RegExp(`^${item}$`)
      })

    for (let i = 0; i < protectedPathListRegex.length; i++) {
      const item = protectedPathListRegex[i]

      if (item.test(route.fullPath)) {
        await userStore.logout()
        await navigateTo({ name: 'frontpage' })
        return
      }
    }

    await userStore.logout()
    await navigateTo({ name: route.name, params: {} })
  } else {
    await userStore.logout()
  }
}

onClickOutside(loginModal, () => {
  hide()
})
// Provide a function to Input.vue so we can get the input field reference.
// With this we can focus the email field when opening the modal
provide(InputFieldInjectionKey, (inputField: HTMLInputElement) => {
  loginInputField.value = inputField
})
</script>
<style lang="scss" scoped>
.custom-loader-animation {
  box-shadow: 10px 0 #2c2ca422, -10px 0 #2c2ca4;
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 10px 0 #2c2ca4, -10px 0 #2c2ca422;
    background: #2c2ca4;
  }
  33% {
    box-shadow: 10px 0 #2c2ca4, -10px 0 #2c2ca422;
    background: #2c2ca422;
  }
  66% {
    box-shadow: 10px 0 #2c2ca422, -10px 0 #2c2ca4;
    background: #2c2ca422;
  }
}
</style>
